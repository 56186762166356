import cn from "classnames";
import styles from "./icon.module.scss";
import useLazyLoadSVG from "./useLazyLoadSVG";
import { Suspense } from "react";

export interface IconProps extends React.ComponentPropsWithoutRef<"svg"> {
  name: string;
  inline?: boolean;
  size?: number;
}

export default function Icon({
  name,
  inline = false,
  size = 10,
  className,
  ...props
}: IconProps) {
  const File = useLazyLoadSVG(name, inline);

  return (
    <Suspense>
      {File ? (
        typeof File === "string" ? (
          <img
            className={cn(styles.img, className)}
            src={File as string}
            alt={name}
            style={{ width: `${size / 10}rem` }}
          />
        ) : (
          <File
            {...props}
            className={className}
            width={`${size / 10}rem`}
            height={`${size / 10}rem`}
            viewBox="0 0 256 256"
          />
        )
      ) : null}
    </Suspense>
  );
}
