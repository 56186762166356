import { RouteObject } from "react-router";
import { Routes } from "src/router/types";

import { AuthGuard } from "src/providers";

export function parseRoutes(routes: Routes): RouteObject[] {
  const parsed: RouteObject[] = [];

  for (const [path, config] of Object.entries(routes)) {
    let { crumb, handle, element, children, visibility, ...rest } = config;

    const routeObject: RouteObject = {
      path,
      element: <AuthGuard visibility={config.visibility}>{element}</AuthGuard>,
      handle: { ...handle, crumb },
      ...rest,
    };
    if (children) routeObject.children = parseRoutes(children);

    parsed.push(routeObject);
  }

  return parsed;
}
