import { Navigate, useLocation } from "react-router";

import { DefaultRoutes } from "src/config";
import { useCurrentUser } from "src/modules/User/store";
import { RouteVisibility } from "src/router/types";

export interface AuthGuardProps {
  children: React.ReactNode;
  noAuthRoute?: boolean;
  visibility?: RouteVisibility;
}

export default function AuthGuard({
  children,
  visibility = "common",
}: AuthGuardProps) {
  const { session } = useCurrentUser();

  const location = useLocation();

  switch (visibility) {
    case "common":
      return <>{children}</>;
    case "protected":
      return session.active ? (
        <>{children}</>
      ) : (
        <Navigate
          to={DefaultRoutes.NO_AUTH}
          state={{ next: location.pathname }}
          replace
        />
      );

    case "public":
      return session.active ? (
        location.state?.next ? (
          <Navigate to={location.state.next} />
        ) : (
          <Navigate to={DefaultRoutes.AUTH} replace />
        )
      ) : (
        <>{children}</>
      );
  }
}
