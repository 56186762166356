import cn from "classnames";
import { useId } from "react";

import { InputProps } from ".";

import styles from "./input.module.scss";

export interface CheckboxProps extends InputProps {
  errorMessage?: string;
}

export default function Checkbox({
  label,
  error,
  errorMessage,
  className,
  ...restInputProps
}: CheckboxProps) {
  const inputId = useId();

  return (
    <div className={cn(styles.container, styles.checkbox, className)}>
      <div className={styles.row}>
        <div className={styles.wrapper}>
          <input
            id={inputId}
            type="checkbox"
            aria-describedby={`${inputId}-label`}
            {...restInputProps}
          />
        </div>

        {label && (
          <label htmlFor={inputId} id={`${inputId}-label`}>
            {label}
          </label>
        )}
      </div>

      {error && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
}
