import Icon from "../Icon/Icon";
import Field, { InputFieldProps } from "./Field";
import { useToggle } from "src/hooks";

export interface PasswordFieldProps
  extends Omit<InputFieldProps, "controlType" | "type"> {}

export default function PasswordField(props: PasswordFieldProps) {
  const [reveal, toggleReveal] = useToggle(false);

  return (
    <Field
      controlType="input"
      type={reveal ? "text" : "password"}
      {...props}
      suffix={
        <button
          onClick={toggleReveal}
          type="button"
          style={{
            display: "grid",
            placeItems: "center",
            backgroundColor: "transparent",
            border: 0,
            width: "36px",
            height: "36px",
          }}
        >
          <Icon name={reveal ? "eye" : "eye-slash"} size={18} inline />
        </button>
      }
    />
  );
}
