import { Routes } from "src/router/types";

const routes: Routes = {
  "service-request": {
    element: <h3>Service Request</h3>,
    crumb: () => "Service Request",
    children: {
      ":id": {
        element: <h4>Children</h4>,
      },
    },
  },
};

export default routes;
