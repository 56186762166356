import cn from "classnames";
import React from "react";
import { Link, To } from "react-router-dom";
import styles from "./button.module.scss";
import { Spinner } from "src/components";

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  variant?: "accent" | "ghost" | string;
  block?: boolean;
  as?: "link" | "button";
  to?: To;
  target?: string;
  loading?: boolean;
  children: React.ReactNode;
}

export default function Button({
  children,
  variant = "",
  block,
  as,
  to = "",
  target,
  loading = false,
  className,
  ...restProps
}: ButtonProps) {
  if (as === "link")
    return (
      <Link
        className={cn(
          styles.base,
          { [styles[variant]]: variant, [styles.block]: block },
          className
        )}
        to={to}
        target={target}
      >
        {children}
      </Link>
    );

  return (
    <button
      type={as}
      className={cn(
        styles.base,
        { [styles[variant]]: variant, [styles.block]: block },
        className
      )}
      disabled={loading}
      {...restProps}
    >
      {loading && <Spinner size={16} color="#fff" />}
      {children}
    </button>
  );
}
