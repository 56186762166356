import React, { useId } from "react";
import classNames from "classnames";

import styles from "./field.module.scss";

export interface InputProps {
  label?: React.ReactNode;
  caption?: string;
  error?: string;
}

export interface FieldProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  unstyledPrefix?: boolean;
  unstyledSuffix?: boolean;
}

export interface TextareaFieldProps
  extends Omit<React.ComponentPropsWithRef<"textarea">, "prefix">,
    InputProps {
  controlType: "textarea";
}

export interface InputFieldProps
  extends Omit<React.ComponentPropsWithRef<"input">, "prefix">,
    FieldProps,
    InputProps {
  controlType: "input";
}

export interface ButtonFieldProps
  extends Omit<React.ComponentPropsWithRef<"button">, "prefix">,
    FieldProps,
    InputProps {
  controlType: "button";
}

function Field(props: TextareaFieldProps): JSX.Element;
function Field(props: InputFieldProps): JSX.Element;
function Field(props: ButtonFieldProps): JSX.Element;
function Field(props: any) {
  const {
    controlType,
    label,
    caption,
    error,
    prefix,
    suffix,
    className,
    ...restControlProps
  } = props;

  const inputId = useId();

  Object.assign(restControlProps, {
    id: `${inputId}-control`,
    className: styles.control,
    "aria-describedby": `${inputId}-caption`,
    style: {
      borderLeftWidth: !prefix || typeof prefix === "string" ? "1px" : 0,
      borderRightWidth: !suffix || typeof suffix === "string" ? "1px" : 0,
    },
  });

  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.error]: error },
        className
      )}
    >
      {label && <label htmlFor={`${inputId}-control`}>{label}</label>}

      <div
        className={classNames(styles.controlWrapper, {
          [styles.prefix]: prefix,
          [styles.suffix]: suffix,
        })}
      >
        {controlType === "input" ? (
          <input {...restControlProps} />
        ) : controlType === "button" ? (
          <button type="button" {...restControlProps} />
        ) : (
          <textarea {...restControlProps} />
        )}

        {prefix && (
          <div
            className={classNames(styles.prefix, {
              [styles.text]: typeof prefix === "string",
              [styles.unstyled]: !!props.unstyledPrefix,
            })}
          >
            {typeof prefix === "string" ? (
              <p className={styles.text}>{prefix}</p>
            ) : (
              prefix
            )}
          </div>
        )}

        {suffix && (
          <div
            className={classNames(styles.suffix, {
              [styles.text]: typeof suffix === "string",
              [styles.unstyled]: !!props.unstyledSuffix,
            })}
          >
            {typeof suffix === "string" ? (
              <p className={styles.text}>{suffix}</p>
            ) : (
              suffix
            )}
          </div>
        )}
      </div>

      {error && (
        <p className={styles.error} role="alert">
          {error}
        </p>
      )}

      {caption && (
        <p id={`${inputId}-caption`} role="note" className={styles.caption}>
          {caption}
        </p>
      )}
    </div>
  );
}

export default Field;
