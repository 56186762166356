import { useEffect } from "react";

import { User } from "src/modules/User/services";
import { Toaster } from "src/components";
import { ErrorBoundary, StoreProvider, SuspenseFallback } from "src/providers";
import { RootRouter } from "src/router";

import "src/styles/global.scss";

export default function App() {
  useEffect(() => {
    User.loadFromCache();

    // Refresh access token every 30 minutes (18,00,000 ms)
    const refreshTokenInterval = setInterval(User.refreshSession, 18_00_000);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, []);

  return (
    <ErrorBoundary>
      <StoreProvider>
        <SuspenseFallback>
          <RootRouter />
        </SuspenseFallback>
        <Toaster />
      </StoreProvider>
    </ErrorBoundary>
  );
}
