import { useEffect, useRef } from "react";

import { PAGE_TITLE_SUFFIX } from "src/config";

export default function useTitle(title: string, absolute: boolean = false) {
  const originalTitleRef = useRef(document?.title);

  const newTitle = absolute ? title : `${title} - ${PAGE_TITLE_SUFFIX}`;

  useEffect(() => {
    if (typeof document === "undefined") return;

    if (document.title !== newTitle) document.title = newTitle;

    const originalTitle = originalTitleRef.current;
    return () => {
      document.title = originalTitle;
    };
  }, [newTitle]);
}
