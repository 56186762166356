import { Navigate, RouterProvider } from "react-router";
import { parseRoutes } from "src/utils";

import userRoutes from "src/modules/User/routes";
import dashboardRoutes from "src/modules/Dashboard/routes";
import serviceRequestRoutes from "src/modules/ServiceRequest/routes";
import { Routes } from "./types";
import { DefaultRoutes } from "src/config";
import { createBrowserRouter } from "react-router-dom";

dashboardRoutes["dashboard"].children = {
  ...dashboardRoutes["dashboard"].children,
  ...serviceRequestRoutes,
};

const routes: Routes = {
  "/": {
    element: <Navigate to={DefaultRoutes.AUTH} replace />,
  },
  ...userRoutes,
  ...dashboardRoutes,
  "*": {
    element: <Navigate to="." replace />,
  },
};

export default function RootRouter() {
  const router = createBrowserRouter(parseRoutes(routes));
  return <RouterProvider router={router} />;
}
