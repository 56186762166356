import React from "react";

import { Routes } from "src/router/types";

const Login = React.lazy(() => import("./views/Login/Login.view"));
const SignUp = React.lazy(() => import("./views/SignUp/SignUp.view"));
const AccountRecovery = React.lazy(
  () => import("./views/AccountRecovery/AccountRecovery.view")
);
const PasswordReset = React.lazy(
  () => import("./views/PasswordReset/PasswordReset.view")
);
const AccountVerification = React.lazy(
  () => import("./views/AccountVerification/AccountVerification.view")
);

const routes: Routes = {
  login: { element: <Login />, visibility: "public" },
  signup: { element: <SignUp />, visibility: "public" },
  "account-recovery": {
    element: <AccountRecovery />,
    visibility: "public",
  },
  "password-reset": {
    element: <PasswordReset />,
    visibility: "public",
  },
  "account-verification": {
    element: <AccountVerification />,
    visibility: "public",
  },
};

export default routes;
