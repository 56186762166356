import { createSlice } from "@reduxjs/toolkit";
import { ToastConfig, ToasterState } from ".";

const initialState: ToasterState = {
  toasts: [],
  nextToastId: 1,
};

const toasterSlice = createSlice({
  name: "toaster",
  initialState,
  reducers: {
    add(state, action: { payload: ToastConfig }) {
      state.toasts.push(action.payload);
      state.nextToastId += 1;
    },
    remove(state, action: { payload: ToastConfig["id"] }) {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
    removeAll(state) {
      state.toasts = [];
    },
  },
});

export const storeActions = toasterSlice.actions;
export const toasterReducer = toasterSlice.reducer;
