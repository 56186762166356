import { useSelector } from "react-redux";

import { RootState } from "../../../store";

export const useCurrentUser = () => {
  return useSelector((state: RootState) => {
    const { profile, auth } = state.user;

    return {
      profile,
      session: {
        active: !!auth.accessToken,
        accessToken: auth.accessToken,
      },
    };
  });
};
