import { createSlice } from "@reduxjs/toolkit";
import { Action, ActionsState, actions } from "./types";

const initialState = actions.reduce(
  (o, key) => ({ ...o, [key]: { callCount: 0, loading: false } }),
  {}
) as ActionsState;

const actionsSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setLoading(state, action: { payload: { action: Action; to?: boolean } }) {
      const { to, action: name } = action.payload;
      state[name].loading = to ?? true;
    },

    setError(state, action: { payload: { action: Action; error?: unknown } }) {
      const { error, action: name } = action.payload;

      state[name].error =
        error instanceof Error ? error : new Error(String(error));
      state[name].loading = false;
    },

    setSuccess(state, action: { payload: { action: Action; to?: boolean } }) {
      const { to, action: name } = action.payload;
      state[name].success = to ?? true;
    },

    incrementCallCount(state, action: { payload: { action: Action } }) {
      const { action: name } = action.payload;
      state[name].callCount++;
    },

    decrementCallCount(state, action: { payload: { action: Action } }) {
      const { action: name } = action.payload;
      if (state[name].callCount <= 1)
        state[name] = { callCount: 0, loading: false };
      else state[name].callCount--;
    },
  },
});

export const storeActions = actionsSlice.actions;
export const actionsReducer = actionsSlice.reducer;
