import { Auth } from "aws-amplify/lib";

import { store } from "src/store";
import { storeActions as userStore } from "src/modules/User/store";
import { storeActions as actionStore } from "src/store/actions";
import { Cache } from "src/lib";
import { UserDetails } from "./types";

const cache = new Cache("user");

class UserService {
  private static instance: UserService;

  private constructor() {}

  static getInstance() {
    if (!this.instance) this.instance = new this();
    return this.instance;
  }

  async signIn({
    username,
    password,
  }: Pick<UserDetails, "username" | "password">): Promise<void> {
    store.dispatch(actionStore.setLoading({ action: "signIn" }));

    try {
      const user = await Auth.signIn(username, password);

      const {
        signInUserSession: {
          idToken: {
            payload: { given_name, family_name, email },
          },
          accessToken: { jwtToken },
        },
      } = user;

      const profile = {
        email: email,
        firstName: given_name,
        lastName: family_name,
      };

      store.dispatch(userStore.updateAuth({ accessToken: jwtToken }));
      store.dispatch(userStore.updateProfile({ profile }));

      cache.set("profile", profile);
      cache.set("accessToken", jwtToken);
    } catch (err) {
      if (err instanceof Error)
        store.dispatch(actionStore.setError({ action: "signIn", error: err }));
    } finally {
      store.dispatch(actionStore.setLoading({ action: "signIn", to: false }));
    }
  }

  async signUp(userDetail: Omit<UserDetails, "username">) {
    store.dispatch(actionStore.setLoading({ action: "signUp" }));

    const { firstName, lastName, email, companyName, phoneNumber, password } =
      userDetail;

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          phone_number: `+91${phoneNumber}`,
          "custom:company_name": companyName,
        },
      });

      store.dispatch(actionStore.setSuccess({ action: "signUp" }));
    } catch (err) {
      if (err instanceof Error) {
        store.dispatch(actionStore.setError({ action: "signUp", error: err }));
      }
    } finally {
      store.dispatch(actionStore.setLoading({ action: "signUp", to: false }));
    }
  }

  async sendRecoveryEmail({ email }: Pick<UserDetails, "email">) {
    store.dispatch(actionStore.setLoading({ action: "sendRecoveryEmail" }));

    try {
      await Auth.forgotPassword(email);

      store.dispatch(actionStore.setSuccess({ action: "sendRecoveryEmail" }));
    } catch (err) {
      if (err instanceof Error)
        store.dispatch(
          actionStore.setError({ action: "sendRecoveryEmail", error: err })
        );
    } finally {
      store.dispatch(
        actionStore.setLoading({ action: "sendRecoveryEmail", to: false })
      );
    }
  }

  async resetPassword({
    code,
    username,
    password,
  }: {
    code: string;
    username: string;
    password: string;
  }) {
    store.dispatch(actionStore.setLoading({ action: "passwordReset" }));

    debugger;

    try {
      await Auth.forgotPasswordSubmit(username, code, password);

      store.dispatch(actionStore.setSuccess({ action: "passwordReset" }));
    } catch (err) {
      if (err instanceof Error)
        store.dispatch(
          actionStore.setError({ action: "passwordReset", error: err })
        );
    } finally {
      store.dispatch(
        actionStore.setLoading({ action: "passwordReset", to: false })
      );
    }
  }

  async confirmUser({ code, username }: { code: string; username: string }) {
    store.dispatch(actionStore.setLoading({ action: "confirmUser" }));

    try {
      await Auth.confirmSignUp(username, code);

      store.dispatch(actionStore.setSuccess({ action: "confirmUser" }));
    } catch (err) {
      if (err instanceof Error)
        store.dispatch(
          actionStore.setError({ action: "confirmUser", error: err })
        );
    } finally {
      store.dispatch(
        actionStore.setLoading({ action: "confirmUser", to: false })
      );
    }
  }

  async signOut() {}

  async loadFromCache() {
    const accessToken = cache.get("accessToken");

    if (accessToken) {
      store.dispatch(
        userStore.updateAuth({ accessToken: accessToken as string })
      );

      await this.refreshSession();

      const profile = cache.get("profile");
      if (profile) store.dispatch(userStore.updateProfile({ profile }));
    } else {
      this.refreshSession();
    }
  }

  async refreshSession() {
    try {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();

      store.dispatch(userStore.updateAuth({ accessToken }));

      cache.set("accessToken", accessToken);
    } catch (err) {
      store.dispatch(userStore.resetAll());
      cache.delete("details");
      cache.delete("accessToken");
    }
  }
}

const User = UserService.getInstance();

export { User };
