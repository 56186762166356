import { createPortal } from "react-dom";

import styles from "./modal.module.scss";

const portal = document.getElementById("portal") as Element;

export interface ModalProps {
  active: boolean;
  onOutsideClick: () => void;
  as?: "modal" | "screen";
  children: React.ReactNode;
}

export default function Modal({
  children,
  as = "modal",
  active,
  onOutsideClick,
}: ModalProps) {
  return createPortal(
    active && (
      <div className={styles.container}>
        <div
          className={styles.backdrop}
          onClick={(e) => {
            if (onOutsideClick && e.target === e.currentTarget)
              onOutsideClick();
          }}
        />
        <div className={styles.window}>{children}</div>
      </div>
    ),
    portal
  );
}
