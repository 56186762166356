import { z } from "zod";

export const signUpFormValidators = {
  firstName: z
    .string()
    .min(1, { message: "Your first name is required" })
    .max(50, {
      message: "First Name should be 50 characters or less",
    }),
  lastName: z
    .string()
    .min(1, { message: "Your last name is required" })
    .max(50, {
      message: "Last Name should be 50 characters or less",
    }),
  email: z
    .string()
    .min(1, { message: "Email address is required" })
    .email({ message: "Email address is invalid" }),
  phone: z
    .string()
    .min(1, { message: "Phone number is required" })
    .length(10, { message: "Phone number is invalid" }),
  password: z
    .string()
    .min(1, { message: "A strong password is required" })
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/,
      { message: "Password must be according to below rules" }
    ),
  organization: z.string(),
  terms: z.literal(true),
};

export const signInFormValidators = {
  username: z
    .string()
    .min(1, { message: "Email address is required" })
    .email({ message: "Email address is invalid" }),
  password: z.string().min(1, { message: "Password is required" }),
};
