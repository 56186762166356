import { Icon } from "src/components";
import ToastContainer from "./ToastContainer/ToastContainer";

export type ToastType = {
  id: number;
  title?: string;
  content?: React.ReactNode;
  icon?: JSX.Element;
  dismissible?: boolean;
  onDismiss?: (id: ToastType["id"]) => void;
};

export const defaultToastStyles = {
  error: {
    title: "Error",
    icon: <Icon name="seal-warning-fill" size={16} inline fill="#E25B55" />,
    className: "error",
  },
};

export default ToastContainer;
