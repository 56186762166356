import cn from "classnames";
import { Icon } from "src/components";
import styles from "./note.module.scss";

const noteTypes = {
  success: {
    icon: "seal-check-fill",
    color: "",
  },
  warning: {
    icon: "seal-warning-fill",
    color: "",
  },
  error: {
    icon: "seal-warning-fill",
    color: "#c14236",
  },
  primary: {
    icon: "note-fill",
    color: "",
  },

  secondary: {
    icon: "note-fill",
    color: "",
  },
};

export interface NoteProps {
  type?: keyof typeof noteTypes;
  filled?: boolean;
  noIcon?: boolean;
  label?: string;
  className?: string;
  children: string;
}

export default function Note({
  type = "primary",
  filled = false,
  noIcon,
  label,
  children,
  className,
}: NoteProps) {
  return (
    <div
      className={cn(
        styles.container,
        styles[type],
        { [styles.filled]: filled },
        className
      )}
    >
      {!noIcon && (
        <Icon
          inline
          name={noteTypes[type].icon}
          fill={noteTypes[type].color}
          size={16}
        />
      )}
      {label && <p className={styles.label}>{label}.</p>}
      <p>{children}</p>
    </div>
  );
}
