export type ActionState = {
  loading: boolean;
  success?: boolean;
  error?: Error;
  callCount: number;
};

export const actions = [
  "signIn",
  "signUp",
  "sendRecoveryEmail",
  "confirmUser",
  "passwordReset",
] as const;

export type Action = (typeof actions)[number];

export type ActionsState = Record<Action, ActionState>;
