import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Button, Note } from "src/components";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

type FallbackProps = {
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
};

function Error({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div style={{ display: "grid", placeItems: "center" }}>
        <Note type="error" label="App Crashed" className="mb-32">
          {String(error)}
        </Note>
        <Button onClick={resetErrorBoundary}>Refresh</Button>
      </div>
    </div>
  );
}

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary fallbackRender={Error}>{children}</ReactErrorBoundary>
  );
}
