import { useState, useEffect, lazy } from "react";

export default function useLazyLoadSVG(
  name: string,
  asJSX: boolean = false
): React.ElementType | string | null {
  const [File, setFile] = useState<React.ElementType | string | null>("");

  useEffect(() => {
    async function importFile() {
      try {
        if (asJSX) {
          setFile(lazy(() => import(`./../../assets/icons/jsx/${name}.jsx`)));
        } else {
          setFile(
            (await import(`./../../assets/icons/svg/${name}.svg`)).default
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    importFile();
  }, [name, asJSX]);

  return File;
}
