import { useMatches } from "react-router";
import { Fragment } from "react";
import { Icon } from "src/components";

import styles from "./breadcrumbs.module.scss";
import { Link } from "react-router-dom";

export default function Breadcrumbs() {
  const matches = useMatches();
  console.log(matches);
  const crumbs = matches
    .filter((match) => Boolean((match.handle as any).crumb))
    .map((match) => [match.pathname, (match.handle as any).crumb()]) as [
    string,
    string
  ][];

  return (
    <div className={styles.crumbs}>
      {crumbs.map((crumb, i) => (
        <Fragment key={i}>
          <p>
            <Link to={crumb[0]}>{crumb[1]}</Link>
          </p>
          {i < crumbs.length - 1 && <Icon name="caret-right-bold" size={14} />}
        </Fragment>
      ))}
    </div>
  );
}
