import cn from "classnames";
import styles from "./logo.module.scss";
import { Link } from "react-router-dom";

import { ReactComponent as LogoMark } from "src/assets/brand/logo-icon.svg";

export interface LogoProps {
  size?: number;
  className?: string;
  color?: "light" | "dark";
  variant?: "wordmark" | "word" | "mark";
}

export default function Logo({
  size = 24,
  className,
  color = "dark",
  variant = "wordmark",
}: LogoProps) {
  return (
    <div className={cn(styles.container, className, styles[color])}>
      <Link to="/" className={styles.link}>
        {["mark", "wordmark"].includes(variant) ? (
          <div
            className={styles.mark}
            style={{ width: size / 6.5 + "rem", height: size / 6.5 + "rem" }}
          >
            <LogoMark />
          </div>
        ) : null}

        {["word", "wordmark"].includes(variant) ? (
          <h2 className={styles.text} style={{ fontSize: size / 10 + "rem" }}>
            Digi Accounto
          </h2>
        ) : null}
      </Link>
    </div>
  );
}
