import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "src/store";
import { Action } from "./types";
import { storeActions } from "./store";

export const useActionState = (action: Action) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeActions.incrementCallCount({ action }));

    return () => {
      dispatch(storeActions.decrementCallCount({ action }));
    };
  }, [action, dispatch]);

  return useSelector((state: RootState) => {
    return state.actions[action];
  });
};
