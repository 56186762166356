import React from "react";
import { Routes } from "src/router/types";

const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard.view"));

const routes: Routes = {
  dashboard: {
    element: <Dashboard />,
    visibility: "protected",
    crumb: () => "Dashboard",
    children: {
      overview: {
        element: <h3>Overview</h3>,
        crumb: () => "Overview",
      },
      staff: {
        element: <h3>Staff</h3>,
        crumb: () => "Staff",
      },
    },
  },
};

export default routes;
