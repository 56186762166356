import { createSlice } from "@reduxjs/toolkit";
import { UserState } from ".";

export const initialState: UserState = {
  auth: {},
  profile: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateProfile(state, action: { payload: Pick<UserState, "profile"> }) {
      state.profile = action.payload.profile;
    },

    updateAuth(
      state,
      action: { payload: { accessToken?: string; refreshToken?: string } }
    ) {
      const { accessToken, refreshToken } = action.payload;
      if (accessToken) state.auth.accessToken = accessToken;
      if (refreshToken) state.auth.refreshToken = refreshToken;
    },

    resetAll(state) {
      state.auth = {};
      state.profile = {};
    },
  },
});

export const storeActions = userSlice.actions;
export const userReducer = userSlice.reducer;
