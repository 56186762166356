import React from "react";
import { Spinner } from "src/components";

function FallbackLoader() {
  return (
    <section
      style={{ minHeight: "100vh", display: "grid", placeItems: "center" }}
    >
      <div>
        <Spinner size={32} />
      </div>
    </section>
  );
}

export default function SuspenseFallback({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <React.Suspense fallback={<FallbackLoader />}>{children}</React.Suspense>
  );
}
