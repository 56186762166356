import cn from "classnames";
import { ToastType } from "..";

import styles from "./toast.module.scss";
import { Icon } from "src/components";

interface ToastProps extends ToastType {
  onDismiss: (id: number) => void;
  className?: string;
}

export default function Toast({
  id,
  title,
  content,
  icon,
  dismissible = true,
  onDismiss,
  className,
}: ToastProps) {
  return (
    <div className={cn(styles.container, styles[className ?? ""])}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.body} style={{ paddingLeft: icon ? 0 : "1.6rem" }}>
        {title && <h4 className={styles.title}>{title}</h4>}
        {content}
      </div>

      {dismissible && (
        <button className={styles.dismiss} onClick={() => onDismiss(id)}>
          <Icon name="x-bold" size={16} inline />
        </button>
      )}
    </div>
  );
}
