import { CachedItem } from "./types";

export class Cache<T> {
  constructor(private readonly namespace: string) {}

  private cKey(key: string): string {
    return `${this.namespace}#${key}`;
  }

  set(key: string, value: T, expireIn?: number) {
    const payload: CachedItem = {
      payload: value,
      expire: expireIn ? Date.now() + expireIn : undefined,
    };

    localStorage.setItem(this.cKey(key), JSON.stringify(payload));
  }

  get(key: string): T | null {
    const item = localStorage.getItem(this.cKey(key));
    if (item) {
      const { payload, expire }: CachedItem = JSON.parse(item);
      if (!expire || expire < +Date.now()) return payload;
    }
    return null;
  }

  delete(key: string) {
    localStorage.removeItem(this.cKey(key));
  }
}
