import PasswordField from "./PasswordField";
import TextField from "./TextField";
import PhoneField from "./PhoneField";
import Checkbox from "./Checkbox";

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: React.ReactNode;
  caption?: React.ReactNode;
  error?: React.ReactNode;
  className?: string;
}

const Input = {
  PasswordField,
  TextField,
  PhoneField,
  Checkbox,
};

export default Input;
