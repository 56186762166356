import { useCallback, useEffect, useMemo, useState } from "react";

export default function useTimer() {
  const [left, setLeft] = useState<number>();
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (running && left)
      timer = setInterval(() => {
        setLeft(left - 1);
      }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  const up = useMemo(() => {
    return left === 0;
  }, [left]);

  const set = useCallback(
    (time: number) => {
      setLeft(time);
    },
    [setLeft]
  );

  const start = useCallback(() => {
    setRunning(true);
  }, []);

  const stop = useCallback(() => {
    setRunning(false);
  }, []);

  return { set, start, stop, left, up };
}
