import { useCallback, useState } from "react";
import { ZodSchema, ZodError } from "zod";

type FieldValue = any;

type FieldProps = {
  name: string;
  validator?: ZodSchema;
  value?: FieldValue;
};

interface FieldData {
  validator?: ZodSchema;
  isDirty: boolean;
  error: string | undefined;
  value: FieldValue;
}

type UseFormProps = {
  validateOn: "blur" | "submit";
};

type FormValues = Record<string, any>;

type SubmitHandler = (formValues: FormValues) => void;

function getInputValue(e: React.ChangeEvent<HTMLInputElement>) {
  const { type } = e.target;

  switch (type) {
    case "checkbox":
      return e.target.checked;
    case "text":
    case "email":
    case "number":
      return e.target.value;
  }

  return e.target.value;
}

export default function useForm(props?: UseFormProps) {
  const [fieldsData, setFieldsData] = useState<Record<string, FieldData>>({});

  function handleSubmit(
    handler: SubmitHandler
  ): React.FormEventHandler<HTMLFormElement> {
    return (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      let isValid = true;
      const formValues: FormValues = {};
      for (const name of Object.keys(fieldsData)) {
        if (!validateField(name)) isValid = false;
        formValues[name] = fieldsData[name].value;
      }

      if (isValid) handler(formValues);
    };
  }

  const validateField = useCallback(
    (name: string) => {
      let message = "";
      try {
        fieldsData[name].validator?.parse(fieldsData[name].value);
      } catch (err) {
        if (err instanceof ZodError)
          message = JSON.parse(err.message)[0].message;
      } finally {
        setFieldsData((prevFieldsData) => {
          return {
            ...prevFieldsData,
            [name]: {
              ...prevFieldsData[name],
              error: message,
            },
          };
        });
      }
      return !message;
    },
    [fieldsData]
  );

  function registerField({ name, validator, value }: FieldProps) {
    if (!fieldsData.hasOwnProperty(name)) {
      setFieldsData({
        ...fieldsData,
        [name]: {
          isDirty: false,
          error: "",
          value: value ?? "",
          validator,
        },
      });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = getInputValue(e);

      setFieldsData({
        ...fieldsData,
        [name]: {
          ...fieldsData[name],
          error: "",
          value,
        },
      });
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setFieldsData({
        ...fieldsData,
        [name]: { ...fieldsData[name], isDirty: true },
      });

      if (props?.validateOn === "blur") validateField(name);
    };

    return {
      name,
      value: fieldsData[name]?.value,
      error: fieldsData[name]?.error,
      onChange: handleChange,
      onBlur: handleBlur,
    };
  }

  return {
    handleSubmit: useCallback(handleSubmit, [fieldsData, validateField]),
    register: useCallback(registerField, [
      fieldsData,
      validateField,
      props?.validateOn,
    ]),
    formData: fieldsData,
  };
}
